<template>
  <div class="trade__application">
    <template v-if="!data.submittedSuccessfully">
      <Form
        id="trade__application__form"
        method="post"
        action="/contact#contact_form"
        accept-charset="UTF-8"
        class="trade__application__form"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <input type="hidden" name="form_type" value="contact" />
        <input type="hidden" name="utf8" value="✓" />

        <div class="trade__application__category">
          <h4>Business Details</h4>
          <div class="trade__application__fieldset__wrap">
            <div class="trade__application__fieldset">
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessFullBusinessName"
                  :disabled="data.disabled"
                  name="contact[business_full_business_name]"
                  class="box"
                  id="business-full-business-name"
                  type="text"
                  label="Full Business Name"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessNatureOfBusiness"
                  :disabled="data.disabled"
                  name="contact[busniess_nature_of_business]"
                  class="box"
                  id="business-nature-of-business"
                  type="text"
                  label="Nature of Business"
                />
              </div>

              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessNzbnNumber"
                  :disabled="data.disabled"
                  name="contact[business_nzbn_number]"
                  class="box"
                  id="business-nzbn-number"
                  type="text"
                  label="NZBN #"
                  :required="true"
                />
              </div>

              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessPhone"
                  :disabled="data.disabled"
                  name="contact[business_phone]"
                  class="box"
                  id="business-phone"
                  type="text"
                  label="Phone"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessBankName"
                  :disabled="data.disabled"
                  name="contact[business_bank_name]"
                  class="box"
                  id="business-bank-name"
                  type="text"
                  label="Bank Name"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessPeriodEstablished"
                  :disabled="data.disabled"
                  name="contact[business_period_established]"
                  class="box"
                  id="business-period-established"
                  type="text"
                  label="Period Established"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-area
                  :value="data.form.businessPostalAddress"
                  :disabled="data.disabled"
                  name="contact[business_postal_address]"
                  class="box"
                  id="business-postal-address"
                  type="text"
                  label="Business Postal Address"
                  :rows="3"
                  :required="true"
                />
              </div>
            </div>
            <div class="trade__application__fieldset">
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessTradingName"
                  :disabled="data.disabled"
                  name="contact[business_trading_name]"
                  class="box"
                  id="business-trading-name"
                  type="text"
                  label="Trading Name (If Different)"
                />
              </div>

              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-select
                  :value="data.form.businessTypeOfBusiness"
                  :disabled="data.disabled"
                  :options="props.business_type_options"
                  class="box"
                  id="business-type-of-business"
                  name="contact[business_type_of_business]"
                  label="Type of Business"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessEmailAddress"
                  :disabled="data.disabled"
                  name="contact[business_email_address]"
                  class="box"
                  id="business-email-address"
                  type="text"
                  label="Email Address"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessFax"
                  :disabled="data.disabled"
                  name="contact[business_fax]"
                  class="box"
                  id="business-fax"
                  type="text"
                  label="Fax"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessBankAddress"
                  :disabled="data.disabled"
                  name="contact[business_bank_address]"
                  class="box"
                  id="business-bank-address"
                  type="text"
                  label="Bank Address"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.businessPeriodAtAddress"
                  :disabled="data.disabled"
                  name="contact[business_period_at_address]"
                  class="box"
                  id="business-period-at-address"
                  type="text"
                  label="Period at Present Address"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-area
                  :value="data.form.businessDeliveryAddress"
                  :disabled="data.disabled"
                  name="contact[business_delivery_address]"
                  class="box"
                  id="business-delivery-address"
                  type="text"
                  label="Business Delivery Address"
                  :rows="3"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="trade__application__category">
          <h4>Contacts</h4>
          <div class="trade__application__fieldset__wrap">
            <div class="trade__application__fieldset">
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-input
                  :value="data.form.contactMainCompanyContact"
                  :disabled="data.disabled"
                  id="main-company-contact"
                  class="box"
                  type="text"
                  name="contact[main_company_contact]"
                  label="Main Company Contact"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.contactEmailAddress"
                  :disabled="data.disabled"
                  id="email"
                  class="box"
                  type="email"
                  name="contact[email]"
                  label="Email Address"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.contactFax"
                  :disabled="data.disabled"
                  id="fax"
                  class="box"
                  type="text"
                  name="contact[fax]"
                  label="Fax"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.contactAccountsContact"
                  :disabled="data.disabled"
                  id="accounts-contact"
                  class="box"
                  type="text"
                  name="contact[accounts_contact]"
                  label="Accounts Contact"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.contactAccountsEmail"
                  :disabled="data.disabled"
                  id="accounts-email"
                  class="box"
                  type="email"
                  name="contact[accounts_email]"
                  label="Accounts Email"
                  :required="true"
                />
              </div>
            </div>
            <div class="trade__application__fieldset">
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-input
                  :value="data.form.contactMainCompanyContactPosition"
                  :disabled="data.disabled"
                  id="main-company-contact-position"
                  class="box"
                  type="text"
                  name="contact[main_company_contact_position]"
                  label="Main Company Contact Position"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.contactPhone"
                  :disabled="data.disabled"
                  id="phone"
                  class="box"
                  type="text"
                  name="contact[phone]"
                  label="Phone"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.contactMobile"
                  :disabled="data.disabled"
                  id="mobile"
                  class="box"
                  type="text"
                  name="contact[mobile]"
                  label="Mobile"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.contactAccountsContactPhone"
                  :disabled="data.disabled"
                  id="accounts-contact-phone"
                  class="box"
                  type="text"
                  name="contact[accounts_contact_phone]"
                  label="Accounts Contact Phone"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.contactAccountsContactFax"
                  :disabled="data.disabled"
                  id="accounts-contact-fax"
                  class="box"
                  type="text"
                  name="contact[accounts_contact_fax]"
                  label="Accounts Contact Fax"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="trade__application__category">
          <h4>Partners/Directors</h4>
          <div class="trade__application__fieldset__wrap">
            <div class="trade__application__fieldset">
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-input
                  :value="data.form.partnerPartnerOne"
                  :disabled="data.disabled"
                  name="contact[partner_partner_one]"
                  class="box"
                  id="partner-partner-one"
                  type="text"
                  label="Partner/Director One"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-area
                  :value="data.form.partnerPrivateAddressOne"
                  :disabled="data.disabled"
                  name="contact[partner_private_address_one]"
                  class="box"
                  id="partner-private-address-one"
                  type="text"
                  label="Partner Private Address One"
                  :required="true"
                  :rows="3"
                />
              </div>
            </div>
            <div class="trade__application__fieldset">
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-input
                  :value="data.form.partnerPartnerTwo"
                  :disabled="data.disabled"
                  name="contact[partner_partner_two]"
                  class="box"
                  id="partner-partner-two"
                  type="text"
                  label="Partner/Director Two"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-area
                  :value="data.form.partnerPrivateAddressTwo"
                  :disabled="data.disabled"
                  name="contact[partner_private_address_two]"
                  class="box"
                  id="partner-private-address-two"
                  type="text"
                  label="Partner Private Address Two"
                  :rows="3"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="trade__application__category">
          <h4>Trade References</h4>
          <div class="trade__application__fieldset__wrap">
            <div class="trade__application__fieldset">
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.tradeNameOne"
                  :disabled="data.disabled"
                  name="contact[trade_name_one]"
                  class="box"
                  id="trade-name-one"
                  type="text"
                  label="Name One"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.tradePhoneNumberOne"
                  :disabled="data.disabled"
                  name="contact[trade_phone_number_one]"
                  class="box"
                  id="trade-phone-number-one"
                  type="text"
                  label="Phone Number One"
                  :required="true"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-area
                  :value="data.form.tradeAddressOne"
                  :disabled="data.disabled"
                  name="contact[trade_address_one]"
                  class="box"
                  id="trade-address-one"
                  type="text"
                  label="Address One"
                  :required="true"
                  :rows="3"
                />
              </div>
            </div>
            <div class="trade__application__fieldset">
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.tradeNameTwo"
                  :disabled="data.disabled"
                  name="contact[trade_name_two]"
                  class="box"
                  id="trade-name-one"
                  type="text"
                  label="Name Two"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--half"
              >
                <v-text-input
                  :value="data.form.tradePhoneNumberTwo"
                  :disabled="data.disabled"
                  name="contact[trade_phone_number_two]"
                  class="box"
                  id="trade-phone-number-two"
                  type="text"
                  label="Phone Number Two"
                />
              </div>
              <div
                class="trade__application__field trade__application__field--full"
              >
                <v-text-area
                  :value="data.form.tradeAddressTwo"
                  :disabled="data.disabled"
                  name="contact[trade_address_two]"
                  class="box"
                  id="trade-address-two"
                  type="text"
                  label="Address Two"
                  :rows="3"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="trade__application__submit">
          <div class="trade__application__terms">
            <Field
              name="contact[read_and_accepted_terms]"
              type="checkbox"
              value="Yes"
              id="read-and-accepted-terms"
            />
            <label for="read-and-accepted-terms"
              >I have read and accepted the
              <span @click="windowOpen('/pages/terms-and-conditions')"
                >Terms and Conditions</span
              ></label
            >
            <ErrorMessage name="contact[read_and_accepted_terms]" />
          </div>
          <button
            :disabled="data.disabled"
            class="btn__base btn__base--dark"
            type="submit"
          >
            Submit Application
          </button>
        </div>
      </Form>
    </template>
    <template v-else>
      <div class="trade__application__success">
        <p class="accent" v-html="props.settings.success_message"></p>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick } from 'vue';
import * as Yup from 'yup';
import { Form, Field, ErrorMessage } from 'vee-validate';
import VTextInput from './VTextInput.vue';
import VTextArea from './VTextArea.vue';
import VSelect from './VSelect.vue';
import { windowOpen } from '../../../utils/helpers';

const props = defineProps({
  settings: {
    type: Object,
    required: true,
    default: () => {},
  },
  business_type_options: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const data = reactive({
  disabled: false,
  submittedSuccessfully: false,
  formAction: '',
  form: {
    businessFullBusinessName: '',
    businessNatureOfBusiness: '',
    businessNzbnNumber: '',
    businessPhone: '',
    businessTradingName: '',
    businessPeriodEstablished: '',
    businessPostalAddress: '',
    businessEmailAddress: '',
    businessBankAddress: '',
    businessPeriodAtAddress: '',
    businessDeliveryAddress: '',
    businessFax: '',
    contactMainCompanyContact: '',
    contactEmailAddress: '',
    contactFax: '',
    contactAccountsContact: '',
    contactAccountsEmail: '',
    contactMainCompanyContactPosition: '',
    contactPhone: '',
    contactMobile: '',
    contactAccountsContactPhone: '',
    contactAccountsContactFax: '',
    partnerPartnerOne: '',
    partnerPartnerTwo: '',
    partnerPrivateAddressOne: '',
    partnerPrivateAddressTwo: '',
    tradeNameOne: '',
    tradePhoneNumberOne: '',
    tradeAddressOne: '',
    tradeNameTwo: '',
    tradePhoneNumberTwo: '',
    tradeAddressTwo: '',
  },
});

const schema = Yup.object().shape({
  'contact[business_full_business_name]': Yup.string().required(
    'Full Business Name is required.'
  ),
  'contact[business_nzbn_number]': Yup.string()
    .matches(/^[0-9,]+$/, 'NZBN number must be numeric.')
    .required('NZBN number is required.'),
  'contact[business_phone]': Yup.string()
    .matches(/^[0-9,]+$/, 'Phone must be numeric.')
    .required('Phone is required.'),
  'contact[business_bank_name]': Yup.string().required(
    'Bank Name is required.'
  ),
  'contact[business_period_established]': Yup.string().required(
    'Period Established is required.'
  ),
  'contact[business_postal_address]': Yup.string().required(
    'Postal Address is required.'
  ),
  'contact[business_email_address]': Yup.string()
    .email('Email Address must be valid')
    .required('Email Address is required.'),
  'contact[business_bank_address]': Yup.string().required(
    'Bank Address is required.'
  ),
  'contact[business_period_at_address]': Yup.string().required(
    'Period at Present Address is required.'
  ),
  'contact[business_type_of_business]': Yup.string().required(
    'Type of Business is required.'
  ),
  'contact[main_company_contact]': Yup.string().required(
    'Main Company Contact is required.'
  ),
  'contact[email]': Yup.string()
    .email('Email must be valid')
    .required('Email is required.'),
  'contact[accounts_contact]': Yup.string().required(
    'Accounts Contact is required.'
  ),
  'contact[accounts_email]': Yup.string()
    .email('Accounts Email must be valid')
    .required('Accounts Email is required.'),

  'contact[main_company_contact_position]': Yup.string().required(
    'Main Company Contact Position is required.'
  ),
  'contact[phone]': Yup.string()
    .matches(/^[0-9,]+$/, 'Phone must be numeric.')
    .required('Phone is required.'),
  'contact[accounts_contact_phone]': Yup.string()
    .matches(/^[0-9,]+$/, 'Phone must be numeric.')
    .required('Accounts Contact Phone is required.'),
  'contact[partner_partner_one]': Yup.string().required(
    'Partner/Director One is required.'
  ),
  'contact[partner_private_address_one]': Yup.string().required(
    'Private Addresss One is required.'
  ),
  'contact[trade_name_one]': Yup.string().required('Name One is required.'),
  'contact[trade_phone_number_one]': Yup.string()
    .matches(/^[0-9,]+$/, 'Phone Number One must be numeric.')
    .required('Phone Number One is required.'),
  'contact[trade_address_one]': Yup.string().required(
    'Address One is required.'
  ),
  'contact[read_and_accepted_terms]': Yup.string().required(
    'You must have read the terms and conditions.'
  ),
});

const onInvalidSubmit = () => {
  data.disabled = true;
  setTimeout(() => {
    data.disabled = false;
  }, 3000);
};

if (window.location.href.includes('contact_posted=true')) {
  data.submittedSuccessfully = !data.submittedSuccessfully;
}
</script>
